.tooltip {
    font-size: 15px;
    width: 320px;
    max-width: 320px;
    background-color: var(--light-grey-color) !important;
    color: var(--black-color) !important;
    padding: 5px 20px 5px 20px;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.3));
    transition: opacity 0.2s translate 0 s !important;
    cursor: default;
}

.tooltipText {
    font-size: 15px;
    width: 320px;
    background-color: var(--light-grey-color);
    color: var(--black-color);
    text-align: left;
    padding: 5px 20px 5px 20px;
    z-index: 1;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.3));
    font-weight: 400;
    font-size: 15px;
}

.tooltipContent {
    white-space: pre-wrap;
}

.tooltipTitle {
    font-weight: bold;
    margin: 10px 0 10px 0;
}

.button {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
}

.button,
.button * {
    font-family: inherit;
    font-size: inherit;
}
