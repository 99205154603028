.rowTitlesText {
    font-size: 14px;
    line-height: 19px;
    color: var(--dark-grey-color);
    padding: 10px;
    flex: content-box;
    flex: 1;
}

.rowTitlesText.iconOnly {
    flex: initial;
    padding: 0 10px;
}

.rowTitlesText.bold {
    font-size: 16px;
    font-weight: 700;
    color: var(--black-color);
}

.rowTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.stocktakingTable .rowTitle .rowTitlesText:nth-of-type(3) {
    padding-left: 15px;
    padding-right: 0px;
}

.stocktakingTable .rowTitle .rowTitlesText:nth-of-type(4) {
    padding-left: 1ch;
    padding-right: 10px;
}

.stocktakingTable .rowTitle .rowTitlesText:nth-of-type(5) {
    padding-left: 45px;
    padding-right: 0px;
}

.stocktakingTable .rowTitle .rowTitlesText:nth-of-type(6) {
    padding-left: 30px;
    padding-right: 10px;
}

.rowTitle.withBorder {
    border-bottom: 1px solid var(--grey-color);
}

.rowTitle.withBackground {
    background-color: var(--light-grey-color);
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 30px;
    border-top: 1px solid var(--grey-color);
    height: 68px;
    transition: 0.3s;
}

.row.loading {
    display: flex;
    gap: 10px;
}

.row.separatorBetween:first-child {
    border-top: 0;
}

.row.clickable {
    transition: color 0.2s ease, background 0.2s ease;
    cursor: pointer;
}

.row.clickable:hover,
.row.clickable:focus-visible,
.row.clickable.active {
    background: var(--deep-black-color);
    color: var(--white-color);
}

.row.clickable.activeGreen {
    background: var(--green-aqua-color);
    color: var(--green-color);
}

.row.clickable:hover > *,
.row.clickable:focus-visible > *,
.row.clickable.active > * {
    color: var(--white-color);
}

.row.clickable.activeGreen > * {
    color: var(--green-color);
}

.row.receivedLineItem {
    background-color: transparent;
    position: relative;
}

.row.hidenReceivedLineItem {
    background-color: transparent;
    position: absolute;
    transform: scaleY(0);
    transform-origin: top left;
    transition: 3s 3s;
    padding-top: 0;
    padding-bottom: 0;
    transition: 10s;
    max-height: 0;
    transition: 0.3s 0.3s;
}

.row:after {
    background-color: transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateX(-101%);
    transition: transform 0.3s, background-color 0s;
}

.row.receivedLineItem:after {
    background-color: var(--green-aqua-color);
    transform: translateX(0);
}

.row.addedLineItem {
    animation: blueFade 2s linear;
}

.field {
    position: relative;
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--black-color);
    flex: 1;
}

.field.grey {
    color: var(--grey-color-50);
}

.field strong {
    color: var(--black-color);
}

.field.withPadding {
    padding: 0 10px;
}

.field.iconOnly {
    flex: initial;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
}

.field.smallFont {
    font-size: 15px;
}

.field.bold {
    font-weight: bold;
}

.field.truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.alignRight {
    text-align: right;
}

.rowDisabled {
    opacity: 0.2;
    pointer-events: none;
}

.endingRowsIcon {
    position: absolute;
    right: 1%;
    margin-top: 15px;
}

.confirmAllButton {
    position: relative;
    right: 5.5%;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
}

.hiddenButton {
    visibility: hidden;
    width: 50px;
}

@keyframes blueFade {
    from {
        background-color: var(--very-light-blue-color);
    }
    60% {
        background-color: var(--very-light-blue-color);
    }
    to {
        background-color: transparent;
    }
}

@media (max-width: 2560px) {

    .stocktakingTable .confirmAllButton {
        right: 6.8%;
    } 
}

@media (max-width: 2400px) {

    .stocktakingTable .confirmAllButton {
        right: 6.6%;
    } 
}


@media (max-width: 2134px) {

    .stocktakingTable .confirmAllButton {
        right: 6.2%;
    }
}

@media (max-width: 2048px) {

    .stocktakingTable .confirmAllButton {
        right: 6%;
    }
}

@media (max-width: 2038px) {

    .stocktakingTable .confirmAllButton {
        right: 6%;
    }
}

@media (max-width: 1920px) {

    .stocktakingTable .confirmAllButton {
        right: 5.5%;
    }
}

@media (max-width: 1910px) {

    .stocktakingTable .confirmAllButton {
        right: 5.3%;
    }
}

@media (max-width: 1746px) {

    .stocktakingTable .confirmAllButton {
        right: 4.5%;
    }
}

@media (max-width: 1707px) {

    .stocktakingTable .confirmAllButton {
        right: 4.3%;
    }
}

@media (max-width: 1698px) {

    .stocktakingTable .confirmAllButton {
        right: 4.2%;
    }
}

@media (max-width: 1560px) {

    .stocktakingTable .confirmAllButton {
        right: 3.0%;
    }
}

@media (max-width: 1528px) {

    .stocktakingTable .confirmAllButton {
        right: 2.9%;
    }
}


@media (max-width: 1440px) {

    .stocktakingTable .confirmAllButton {
        right: 5%;
        width: 45px;

    }

}


@media (min-width: 1396px) and (max-width: 1397px) {


    .stocktakingTable .confirmAllButton {
        right: 4.5%;

    }
    
}

@media (min-width: 1390px) and (max-width: 1395px) {


    .stocktakingTable .confirmAllButton {
        right: 4.6%;

    }
    
}

@media (min-width: 1370px) and (max-width: 1389.09px) {


    .stocktakingTable .confirmAllButton {
        right: 4.4%;

    }
    
}

@media (min-width: 1362px) and (max-width: 1369px) {

    .stocktakingTable .confirmAllButton {
        right: 4.5%;

    }
    
}

@media (min-width: 1335px) and (max-width: 1362px) {

    .stocktakingTable .confirmAllButton {
        right: 4.4%;

    }
    
}

@media (min-width: 1321px) and (max-width: 1335px) {

    .stocktakingTable .rowTitle .rowTitlesText {
        font-size: 12px;
    }

    .stocktakingTable .field {
        font-size: 12px;
    }

    .stocktakingTable .confirmAllButton {
        right: 4.2%;

    }
}

@media (min-width: 1230px) and (max-width: 1320px) {

    .stocktakingTable .rowTitle .rowTitlesText {
        font-size: 12px;
    }

    .stocktakingTable .field {
        font-size: 12px;
    }

    .stocktakingTable .confirmAllButton {
        right: 4.2%;

    }
    
}

@media (min-width: 1025px) and (max-width: 1229px) {

    .stocktakingTable .rowTitle .rowTitlesText {
        font-size: 12px;
    }

    .stocktakingTable .field {
        font-size: 12px;
    }

    .stocktakingTable .confirmAllButton {
        right: 3.3%;

    }
    
}

@media (min-width: 1025px) and (max-width: 1223px) {

    .stocktakingTable .rowTitle .rowTitlesText {
        font-size: 12px;
    }

    .stocktakingTable .field {
        font-size: 12px;
    }

    .stocktakingTable .confirmAllButton {
        right: 3.3%;

    }
    
}

@media (min-width: 1020px) and (max-width: 1024px) {

    .stocktakingTable .rowTitle .rowTitlesText {
        font-size: 12px;
    }

    .stocktakingTable .field {
        font-size: 12px;
    }

    .stocktakingTable .confirmAllButton {
        right: 1.2%;

    }
    
}


@media (min-width: 780px) and (max-width: 1019px) {

    .stocktakingTable .rowTitle .rowTitlesText {
        font-size: 12px;
    }

    .stocktakingTable .field {
        font-size: 12px;
    }

    .stocktakingTable .confirmAllButton {
        right: 1.2%;

    }
    
}