.columnHeadline {
    font-weight: 400;
    color: var(--grey-color-50);
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 50px;
}

.removedItem {
    text-decoration: line-through;
    color: var(--dark-grey-color);
}

.introduction {
    color: var(--dark-grey-color);
    text-align: center;
}
