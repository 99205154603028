.leftArrowContainer {
    padding: 4px 20px 10px 0;
}

.rightArrowContainer {
    padding: 4px 0 10px 20px;
}

.arrowButton {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 23;
    transition: ease 0.5s;
    cursor: pointer;
}

.arrowButtonLight {
    background-color: var(--light-grey-color);
}

.arrowButtonDark {
    background-color: var(--ash-grey-color);
}

.arrowBlack {
    color: var(--black-color);
}

.arrowGrey {
    color: var(--text-disabled-color);
}

.arrowRotated {
    transform: rotate(180deg);
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}