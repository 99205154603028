.tabsList {
    border-bottom: 1px solid var(--grey-color);
}

.tabsList.withPaddedWrapper {
    /* Account for section padding */
    margin-left: -40px;
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% + 80px);
}

.tabsTrigger {
    font-size: 18px;
    color: var(--text-disabled-color);
    width: 140px;
    padding: 15px;
    cursor: pointer;
    transition: border-color 0.3s ease, color 0.3s ease;
    /* Reset browser button styles */
    background: none;
    border: 0;
    border-bottom: 4px solid transparent;
}

.tabsTrigger[data-state='active'],
.tabsTrigger:focus-visible,
.tabsTrigger:hover {
    color: var(--black-color);
    border-bottom-color: var(--black-color);
}

.tabsTrigger:focus-visible {
    /* Customize browser focus style */
    outline: 1px dotted var(--black-color);
}

.tabsTrigger.tabWithError {
    color: var(--brick-red-color);
    border-color: var(--brick-red-color);
}
