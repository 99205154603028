.container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.title {
    margin: 42px 0 5px 0;
    font-size: 14px;
    color: #555e68;
}

.subTitle {
    margin: 0 0 23px 0;
    font-size: 31px;
    font-weight: 500;
}

.capitalized {
    text-transform: capitalize;
}

.description {
    margin: 0 100px 7px 100px;
    font-size: 15px;
}
