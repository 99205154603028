.listItem {
    display: flex;
    text-align: center;
    align-items: center;
    height: 72px;
    cursor: pointer;
    background-color: var(--light-grey-color);
    border-bottom: solid 2px var(--ash-grey-color);
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: center;
}

.listItem:hover {
    background-color: var(--ash-grey-color);
}

.listContainer input[type='radio'] {
    display: none;
}

.listContainer {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.listContainer input[type='radio']:checked + .listItem {
    background-color: black;
    color: #fff;
    border: none;
}