.container {
    max-width: 450px;
    margin: 130px auto 0 auto;
}

.noMargins {
    margin: 0 auto;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    margin-top: 62px;
    text-align: center;
}

.noMarginTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 30px;
}

.subTitle {
    text-align: center;
    color: var(--grey-color-50);
}
