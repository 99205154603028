.textInputLabelStrong,
.textInputLabelStrong {
    position: relative;
}

.textInputLabel {
    font-size: 15px;
    color: var(--text-disabled-color);
}

.textInputLabelStrong {
    font-weight: 400;
    font-size: 18px;
    color: var(--black-color);
}

.textInput {
    background-color: var(--light-grey-color);
    color: var(--black-color);
    font-family: inherit;
    font-size: 18px;
    padding: 20px 30px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.textInput.noMargin {
    margin: 0;
}

.textInputWithError {
    border: 2px solid var(--error-color);
}

.textInputWithError:focus {
    border: 2px solid transparent;
    border-bottom-color: var(--black-color);
}

.textInput[disabled] {
    background-color: var(--main-background);
    color: var(--dark-grey-color);
    border: 1px solid var(--ash-grey-color);
    cursor: default;
}

.textInput.withIcon {
    position: relative !important;
    display: flex;
    justify-content: space-between;
}

.textInput:focus-visible {
    border-bottom-color: var(--black-color);
}

.errorMessage {
    color: var(--error-color);
    font-size: 15px;
}

.inputContainer {
    margin-top: 10px;
    position: relative;
}

.inputIcon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.optionalLabel {
    color: var(--text-disabled-color);
}

.customNumberInput::-webkit-inner-spin-button,
.customNumberInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
