.navbar {
    width: var(--sideNavigation-width);
    height: 100%;
    padding: 40px 0 0 0;
    background-color: var(--light-grey-color);
    position: fixed;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    z-index: var(--z-index-default);
}

.navItem {
    display: flex;
    height: 45px;
    align-items: center;
    color: var(--text-disabled-color);
    font-size: 16px;
    margin-left: 30px;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    position: relative;
}

.navItemActive {
    color: var(--black-color);
}

.navItemActive::before {
    content: '';
    width: 4px;
    height: 45px;
    position: absolute;
    margin-left: -30px;
    background-color: var(--black-color);
}

.navLabel {
    padding: 0 30px;
}

.navBottom {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
}

.userProfile {
    font-size: 14px;
    color: var(--black-color);
}

.appVersionLabel {
    font-size: 12px;
    margin: 0;
    display: flex;
    justify-content: center;
}

.category {
    font-size: 16px;
    font-weight: 700;
    color: var(--black-color);
    margin: 37px 0 18px 30px;
}

.category:first-child {
    margin-top: 0;
}

.navFooter {
    min-height: calc(var(--footer-height) - 1px);
}

.logo {
    margin-top: 39px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}

.quickActionsContainer {
    width: 150px;
    margin-left: 30px;
}

.newOrderButton {
    pointer-events: none;
}

.sideNavItem {
    cursor: pointer;
}

.termsOfUse {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black-color);
    font-size: 13px;
    margin-top: 5px;
    padding-bottom: 20px;
}

@media (max-width: 1440px) {
    .navbar {
        display: none;
    }

    .hamburgerContainer {
        display: block;
        position: fixed;
        top: 0.7rem;
        left: 1rem;
        z-index: 15; 
    }

    .dialogContent {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: var(--sideNavigation-width);
        height: 100%;
        background-color: var(--light-grey-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        overflow-y: auto;
        padding: 1rem;
        transform: translateX(-100%); 
        opacity: 0; 
        visibility: hidden; 
        transition: transform 1.5s ease, opacity 1.5s ease, visibility 0s 1.5s; 
    }

    .dialogContent.open {
        display: block; 
        transform: translateX(0); 
        opacity: 1;
        visibility: visible; 
        padding-top: 30px;
    }

    .hamburgerButton {
        background: var(--main-background);
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
        color: var(--black-color);
    }

    .hamburgerContainer.hidden {
        display: none;
    }

    .closeButton {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 2rem;
        color: var(--black-color);
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        padding-top: 8px;
    }

    .dialogContent .navItem {
        margin-left: 0;
        padding: 1rem;
    }

    .dialogContent .navLabel {
        padding: 0;
    }

    .category {
        margin: 37px 0 20px 22px;
    }

    .quickActionsContainer {
        width: 150px;
        margin-left: 15px;
    }
}
