.container {
    position: fixed;
    margin-bottom: 5px;
    top: var(--page-topbar-height);
    right: 0;
    left: var(--sideNavigation-width);
    background: var(--main-background);
    display: grid;
    grid-template-columns: repeat(2, auto);
    padding: 30px 40px;
    transition: var(--transition-slow);
    border: 1px solid transparent;
    z-index: var(--z-index-topbar);
}

.container.collapsed {
    padding: 15px 40px;
    align-items: center;
}

.container.collapsed > h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-grey-color);
}

.backButton {
    /* Makes the breadcrumbs cell stretch over both grid columns */
    grid-column-start: 1;
    grid-column-end: 3;
}

.statusInfo {
    display: flex;
    margin-left: 10px;
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.changelogButton {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-grey-color);
    border: none;
    cursor: pointer;
}

.changelogButtonBadge {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: -7px;
    left: 26px;
    background: var(--brick-red-color);
    color: var(--white-color);
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spacer {
    height: var(--order-topbar-height);
}

.withBadge {
    display: flex;
    align-items: center;
}

.receivalButtonWrapper {
    margin-left: 20px;
}

.sspoTooltipInfoContainer {
    display: flex;
    justify-content: space-between;
}

.sspoTooltipContactText {
    font-weight: bold;
}

.sspoTooltipContactTextBlue {
    font-weight: bold;
    color: blue;
}

.addItemsButtonWrapper {
    margin-right: 10px;
}

.skeletonLoaderContainer {
    height: 40px;
    display: flex;
    align-items: center;
}

@media (max-width: 1440px) {

    .container {

        left: 0px;
    }

}