.starComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 20px 0 20px;
    /* Reset button styles */
    background: none;
    border: 0;
}

.smallerStarComponent {
    margin: 0 11px 0 11px;
}

.text {
    margin-top: 40px;
    color: var(--silver-color);
    font-weight: 500;
    font-size: 14px;
}

.smallerComponentText {
    margin-top: 25px;
    font-size: 12px;
}

.blueText {
    color: var(--blue-color);
}

.darkGreyText {
    color: var(--grey-color-50);
}

.starsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.redText {
    color: #ff5c5c;
}