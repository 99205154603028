.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px;
    height: 100%;
}

.container h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 34px 0;
}

.container p {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--grey-sending-status-badge);
    margin: 0 0 30px 0;
}

.container p:last-child {
    margin: 0;
}

.overlay {
    z-index: 200;
}
