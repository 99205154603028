.toggleButton,
.knob {
    transition: 0.2s;
}

.toggleButton {
    width: 31px;
    height: 18.84px;
    background: #e9e9eb;
    border-radius: 15px;
    position: relative;
    transition: 0.3s;
}

.toggleButton.active {
    background: #34c759;
}

.toggleButton .knob {
    width: 16.41px;
    height: 16.41px;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: calc(0% + 9.75px);
    transform: translate(-50%, -50%);
    background: var(--white-color);
}

.toggleButton.active .knob {
    left: calc(100% - 9.75px);
}
