.buttonIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    background: transparent;
    transition: background .2s ease;
}

.buttonIconContainer:hover,
.buttonIconContainer:focus-visible {
    background: var(--grey-color);
}

@media (max-width: 1440px) {

    .buttonIconContainer {
        margin-left: 60px;
    }

}