.animationContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: var(--z-index-waves);
    opacity: 0;
    animation: fadeIn 0.5s 0s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}