.container { 
    padding: 18px; 
    padding-top: 60px;
}

.table {
    display: flex;
    flex-direction: column;   
    font-size: 0.9rem;
}

.tableSummary {
    display: flex;
    flex-direction: column;   
    margin-bottom: 30px;
    
}

.tableRow {
    display: flex; 
    border: 1px solid #ddd;
    flex-direction: row;
}

.tableRowSummary {
    display: flex;  
    flex-direction: row;
}

.tableHeader {
    font-weight: bold;
    background-color: #f0f0f0;
}

.tableCell {
    flex: 1;
    padding: 8px;
    border: 1px solid #d7d7d749;
    word-break:break-all;    
    hyphens: auto;
}

.tableCellHeader {
    flex: 1;
    padding: 8px;
    border: 1px solid #d7d7d749;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.tableCellParentCategory {
    flex: 1;
    padding: 5px; 
    background-color: lightgray;
}

.tableCellBorderless {
    flex: 1;
    padding: 5px; 
}

.s1 {
    width: 64px;  
    flex: none;
    text-align: center;  
}

.s2 {
    width: 96px;  
    flex: none;  
}

.s3 {
    width: 256px;  
    flex: none;  
}

.badge {
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 3px 10px;
    margin-bottom: 8px;
    font-size: .8em;
    font-weight: 500;
    font-family: 'Courier New', Courier, monospace;  
    text-align: right; 
    width:fit-content;  
}

.pricePerPack {
    display: flex;
    flex-direction: column;
}