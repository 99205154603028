.tableCell {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;
}

.tableCell:nth-child(1) {
    width: 32%;
}

.tableCell:nth-child(2) {
    width: 10%;
}

.tableCell:nth-child(3),
.tableCell:nth-child(4),
.tableCell:nth-child(5) {
    flex: 1;
}

.tableCell:nth-child(6) {
    width: 60px;
}

.tableCellArrowIcon {
    justify-content: flex-end;
}

.tableCellButtons {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60px;
}
