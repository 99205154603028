.dropdownContainer {
    position: relative;
}

.triggerButton {
    border: none;
    outline: none;
    cursor: pointer;
    background: var(--light-grey-color);
    width: 44px;
    height: 44px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.trigger {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
}

.dropdownContent {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--light-grey-color);
    width: 250px;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.25);
}

.dropdownContent.withTriggerButton {
    top: 0px;
    right: 10px;
}

.dropdownContent.top {
    top: 20px;
    transform: translateY(calc(-100% - 55px));
}

.dropdownItem {
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.paddings {
    padding: 30px;
}

.dropdownItem:not(:last-of-type) {
    border-bottom: 1px solid var(--grey-color);
}

.dropdownItem:hover {
    background-color: var(--grey-color);
}

.dropdownItemBolded {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    background-color: transparent;
    cursor: pointer;
}
