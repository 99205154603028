/*
As we have radix library already in project, I wanted to use it to create dropdown.
But there is an issue when trigger button is position fixed.
Styles below are workaround.

more info here ->  https://github.com/radix-ui/primitives/issues/781
*/

[data-radix-popper-content-wrapper] {
    position: absolute !important;
    top: 100% !important;
    width: 100% !important;
    right: 0% !important;
    left: auto !important;
    transform: none !important;
    transition: 0s !important;
    z-index: var(--z-index-dropdown) !important;
}
