.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-grey-color);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0;
}

.iconOrange {
    background-color: var(--light-orange);
}

.flag {
    width: 15px;
    height: 10px;
    margin-bottom: 16px;
    margin-right: 5px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.emptyState {
    color: var(--black-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 40px 0 200px 30px;
}

.lastColumnEmptyTitle {
    width: 20px;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.ellipsis:hover::after {
    content: attr(title);
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #fff; 
    padding: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    z-index: 1;
    max-width: none;
    white-space: normal;
}