.container p,
.container h3 {
    margin: 0;
    padding: 0;
}

.container {
    text-align: center;
    margin-bottom: 80px;
}

.stepProgressBar {
    margin-top: -90px;
}

.stepTitle {
    font-size: 31px;
}
