.orderContainer {
    position: relative;
}

.contentContainer {
    padding: 25px 40px 0;
}

.sectionHeading {
    font-size: 18px;
    color: var(--black-color);
    margin: 0;
    margin-top: 15px;
    font-weight: 400;
}

.budgetFigures {
    display: grid;
    grid-template-columns: repeat(4, minmax(140px, 1fr));
    gap: 20px;
    margin: 20px 0;
}
