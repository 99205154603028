.flag {
    width: 15px;
    height: 10px;
    margin-bottom: 30px;
    margin-right: 5px;
}

.portLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    margin: 0 5px;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0 30px 0 0;
}
