.filtersButton {
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
}

.withBlueDot::after {
    content: '';
    position: absolute;
    top: 0;
    right: 6px;
    width: 9px;
    height: 9px;
    background-color: var(--blue-color);
    z-index: 2;
    border-radius: 100%;
    transform: translate(50%, -20%);
}
