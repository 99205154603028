.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;
    width: 100%;
    padding: 14px 20px;
    border-bottom: 1px solid #e9ebed; 
}

.container p {
    margin: 0;
    font-size: 16px;
}

.container .helper {
    color: #7a808d;
    margin: 0;
    font-size: 12px;
}

.value {
    text-align: right;
}

.textContainer {
    display: flex;
    flex-direction: column;
    width: 70%; 
}

.label { 
    display: flex;
} 