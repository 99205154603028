.container {
    position: fixed;
    bottom: 0;
    background-color: var(--light-grey-color);
    height: 60px;
    width: var(--modal-width);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    z-index: var(--z-index-searchbox-in-modal);
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 100%;
    height: 32px;
    background-image: linear-gradient(transparent, var(--white-color));
    pointer-events: none;
}

.containerWhenButtonAppear {
    bottom: 60px;
}

.input {
    border: none;
    background: var(--light-grey-color);
    height: 55px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    outline: none;
    font-family: 'Satoshi', serif;
}

.input::placeholder {
    font-size: 15px;
    color: var(--grey-color-50);
}

.loading {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    position: relative;
    padding: 3px;
    box-sizing: border-box;
    flex-shrink: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.loading.visible {
    opacity: 1;
}

.loading.visible::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid transparent;
    border-top: 2px solid var(--blue-color);
    border-right: 2px solid var(--blue-color);
    border-left: 2px solid var(--blue-color);
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    animation: spin 2s linear infinite;
    transform-origin: top left;
}

@keyframes spin {
    0% {
        transform: rotate(0deg) translate(-50%, -50%);
    }
    100% {
        transform: rotate(360deg) translate(-50%, -50%);
    }
}
