.inputContainer {
    width: calc(50% - 20px);
    position: relative;
    margin-top: 40px;
    height: 100px;
}

.inputsInRowContainer {
    display: flex;
    justify-content: space-between;
}

.contentContainer {
    padding: 0 130px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 60px;
}