.inputContainer {
    width: calc(50% - 20px);
    position: relative;
    margin-top: 40px;
}

.inputsInRowContainer {
    display: flex;
    justify-content: space-between;
}
