.root {
    position: fixed;
    bottom: 32px;
    right: 12px;
    list-style: none;
    transition: 0.15s;
    z-index: var(--z-index-toast);
}

.toastContainer {
    padding: 0 16px;
    min-width: 222px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.toastContainer.success {
    background: var(--light-green-color-2);
    color: var(--white-color);
}

.toastContainer.error {
    background: var(--red-color);
    color: var(--white-color);
}

.iconContainer {
    margin-right: 18px;
}

.root.open {
    transform: translateX(0);
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.root.close {
    transform: translateX(calc(100% + 20px));
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.viewport {
    margin: 0;
}
