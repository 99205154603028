.container {
    height: 150px;
    padding: 30px;
    background-color: var(--light-grey-color);
    color: var(--black-color);
    position: relative;
    margin-bottom: 20px;
    transition: 0.2s;
    cursor: pointer;
    border: 0;
    display: flex;
    width: 100%;
    text-align: left;
}

.container::after {
    position: absolute;
    top: 30px;
    right: 30px;
    content: '';
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid black;
    box-sizing: border-box;
}

.container.selected {
    background-color: var(--black-color);
    color: var(--white-color);
}

.container.small {
    height: 80px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.container.selected::after {
    border: 7px solid white;
}

.title {
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    font-family: inherit;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 50px;
}

.dateLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    margin: 0 5px;
    color: var(--grey-color-50);
}
