.container {
    position: relative;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden; 
}

.container::before,
.container::after {
    content: '';
    top: 0;
    height: 100%;
    width: 100px;
    position: absolute;
    z-index: var(--z-index-categories-bar-container);
    transition: 0.2s;
    display: block;
    pointer-events: none;
}

.container::before {
    background: linear-gradient(
            to left,
            transparent 0%,
            rgba(255, 255, 255, 0.9) 60%
    );
    opacity: 0;
    top: 0;
    left: 0;
    z-index: var(--z-index-categories-bar-container);
    height: 79px
}

.container::after {
    background: linear-gradient(
            to right,
            transparent 0%,
            rgba(255, 255, 255, 0.9) 60%
    );
    opacity: 0;
    right: 0;
    top: 0;
    height: 79px;
    z-index: var(--z-index-categories-bar-container);
}

.container.overflowLeft::before {
    opacity: 1;
}

.container.overflowRight::after {
    opacity: 1;
    display: block;
    pointer-events: none;
}

.container.margins::after {
    margin-right: 70px;
}

.container.margins::before {
    margin-left: 70px;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .container {
        padding: 5px 10px;
    }

    .container::before,
    .container::after {
        width: 50px; 
    }
}
