.commentButton {
    border: none;
    background: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3px 5px;
}

.commentButton.disabled {
    cursor: default;
}

.commentButton.withBackground {
    background: var(--light-grey-color);
    width: 44px;
    height: 44px;
    border-radius: 100%;
    padding: 0;
    margin: 0;
}

.commentButton:disabled,
.commentButton[disabled] {
    opacity: 0.35;
    pointer-events: none;
}

.commentButton.hasComment::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background-color: var(--brick-red-color);
    transform: translate(-13px, -1px);
}

.disablePointerCursor {
    cursor: auto;
}
