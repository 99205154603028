.inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.deliveryPortInputContainer {
    width: 290px;
}
