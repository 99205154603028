.statusInfoBox {
    border-radius: 50px;
    display: flex;
    height: 30px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 16px 0 16px;
    max-width: 160px;
    position: relative;
}

.forTopBar {
    min-width: 100px;
}

.statusInfoBox.offlineGrey {
    background-color: var(--grey-sending-offline-status-badge);
}

.statusInfoBox.orange {
    background-color: var(--light-orange);
}

.statusInfoBox.grey {
    background-color: var(--light-grey-color);
}

.statusInfoBox.completed {
    max-width: 130px;
}

.statusInfoBox.purple {
    background-color: var(--light-purple);
}

.statusInfoBox.lightBlue {
    background-color: var(--light-blue-color);
}

.statusInfoBox.green {
    background-color: var(--very-light-green-color);
}

.statusInfoBoxAnimated {
    width: 160px;
    overflow: hidden;
}

.statusInfoBoxAnimated:before {
    content: '';
    background: linear-gradient(
            0.25turn,
            var(--light-blue-color),
            var(--very-light-blue-color)
    );
    filter: blur(0.7rem);
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: linearProgress 1s linear infinite;
}

.textStatus {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    /* Use ellipsis when label is too long */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
}

.textStatus.offlineGrey {
    color: var(--grey-sending-status-badge);
    z-index: 1;
}

.textStatus.orange {
    color: var(--orange-color);
}

.textStatus.grey {
    color: var(--grey-color-50);
}

.textStatus.purple {
    color: var(--dark-purple);
}

.textStatus.blue {
    color: var(--blue-color);
}

@keyframes linearProgress {
    0% {
        left: 0;
        right: 100%;
        width: 30%;
    }
    10% {
        left: 0;
        right: 70%;
        width: 50%;
    }
    90% {
        right: 0;
        left: 70%;
        width: 100%;
    }
    100% {
        left: 100%;
        right: 0;
        width: 0;
    }
}
