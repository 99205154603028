.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.container.withText.primary {
    margin: 27px 0 25px 0;
}

.container.withText.withMarginBottom {
    margin: 0 0 14px 0;
}

.container.withText.noMargin {
    margin: 0;
}

.container.small {
    margin: 8px 0;
}

.pending {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    position: relative;
    padding: 3px;
    box-sizing: border-box;
}

.pending::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid transparent;
    border-top: 2px solid var(--blue-color);
    border-right: 2px solid var(--blue-color);
    border-left: 2px solid var(--blue-color);
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    animation: spin 2s linear infinite;
    transform-origin: top left;
}

@keyframes spin {
    0% {
        transform: rotate(0deg) translate(-50%, -50%);
    }
    100% {
        transform: rotate(360deg) translate(-50%, -50%);
    }
}

.iconContainer {
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    margin: 0 0 0 12px;
    font-weight: 500;
    font-size: 15px;
    font-family: 'Satoshi', serif;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 15px;
}

.small .text {
    font-weight: 400;
}

.bold .text {
    font-weight: 500;
}

.withText {
    position: relative;
    padding: 0 17px;
}

.primary::before {
    position: absolute;
    content: '';
    display: block;
    width: 2px;
    height: 25px;
    transform: translateX(-17px);
}

.primary.online::before {
    background-color: var(--light-green-color-2);
}

.primary.offline::before {
    background-color: #ff5a5a;
}

.primary.blue::before {
    background-color: var(--blue-color);
}

.optionalHelper {
    font-weight: 400;
    font-size: 12px;
    color: var(--grey-sending-status-badge);
    padding: 0 19px;
    margin: -12px 0 17px 0;
    display: block;
}

.toggleButtonContainer {
    margin: 0 0 0 auto;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 12px;
}
