.orderListHeading {
    margin-top: 50px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.orderListHeading > button {
    color: var(--blue-color);
}

.orderListHeading p {
    margin: 0;
}