.container {
    position: relative;
}

.dropdownList {
    max-height: 340px;
    width: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: white;
    border: 1px solid var(--grey-color);
    list-style: none;
    z-index: 5;
    overflow-y: auto;
}

.dropdownItem {
    padding: 20px 30px;
    transition: 0.2s;
    border-bottom: 1px solid var(--grey-color);
    cursor: pointer;
    color: var(--black-color);
}

.dropdownItem.highlighted {
    background-color: var(--grey-color);
}
