.tableContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 100px 40px 100px;
}

.tableContainer.lessSpacing {
    padding: 0 100px;
}

.rowContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-color);
}

.rowContainer.dark {
    border-bottom: 1px solid var(--white-color-muted);
}

.rowContainer:last-of-type,
.rowContainer.withoutSeparator {
    border-bottom: none;
}
.rowContainer.withMarginBottom {
    margin-bottom: 32px;
}

.rowContainer.iconOverflow {
    width: calc(100% - 70px);
}

.rowTitle {
    font-size: 16px;
    line-height: 22px;
    color: var(--grey-color-50);
    padding: 0;
}

.dark .rowTitle {
    color: var(--white-color);
    font-weight: 300;
}

.iconContainer {
    padding: 0 14px;
}

.edgeIcon .iconContainer {
    padding: 0 14px 0 4px;
}

.boldedRowTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--black-color);
    padding: 10px 0 10px 0;
}

.sectionTitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 17px;
    margin-top: 50px;
}

.rowValue {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--grey-color-50);
}

.boldedRowValue {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--black-color);
}

.dark .boldedRowValue {
    color: var(--white-color);
}

.uppercaseText {
    text-transform: uppercase;
}

.button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
}

.lessSpacing p {
    margin-bottom: 0;
}
