.button {
    font-size: 21px;
    background: #121921;
    color: var(--white-color);
    padding: 0.8em 2.85em;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: var(--z-index-button);
    font-family: inherit;
}

.squaredButton {
    height: 60px;
    width: 100%;
    flex: 1;
}

.secondaryButton {
    background-color: var(--secondary-button-color);
    color: var(--black-color);
}

.primaryButton {
    background-color: var(--blue-color);
    color: var(--white-color);
}

.redButton {
    background-color: var(--light-red-color);
    color: var(--red-color);
}

.blackSquaredButton {
    background-color: var(--black-color);
    color: var(--white-color);
}

.button:focus-visible {
    background: var(--dark-grey-color);
}

.button.smallRounded {
    font-size: 14px;
    padding: 10px 47px;
    font-weight: 500;
    border-radius: 60px;
    height: 38px;
    line-height: 12px;
}

.button.thin {
    font-weight: 400;
    font-size: 16px;
    padding: 5px 19px;
}

.button.light {
    background: var(--ash-grey-color);
    color: var(--black-color);
}

.button.blueCta,
.button.blackCta,
.button.greyCta,
.button.whiteCta,
.button.redCta {
    padding: 10px 47px;
    font-weight: 400;
    border-radius: 60px;
}

.button.buttonWithIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 30px;
}

.button.whiteCta {
    color: var(--black-color);
    background: var(--white-color);
    border: 1px solid var(--white-color);
}

.button.blueCta {
    color: var(--white-color);
    background: var(--blue-color);
}

.button.blackCta {
    color: var(--white-color);
    background: var(--black-color);
}

.button.redCta {
    color: var(--red-color);
    background: var(--light-red-color);
}

.button.greyCta {
    background: var(--ash-grey-color);
    color: var(--black-color);
}

.button.greenCta {
    color: var(--white-color);
    background: var(--green-color);
}

button.button[disabled],
button.button.light[disabled] {
    background-color: var(--disabled-button-color);
    color: var(--disabled-button-text-color);
    cursor: default;
}

.button.lightBlue {
    color: var(--blue-color);
    background: var(--light-blue-color-with-transparency);
}

.button.noWrap {
    white-space: nowrap;
}

button.text {
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    padding: 0;
}

.button.transparent {
    background-color: transparent;
    border: 1px solid var(--blue-color);
    border-radius: 40px;
    color: var(--blue-color);
    font-weight: 400;
    padding: 10px 24px;
    transition: 0.3s ease background;
}

.button.transparentWhite {
    border: 1px solid var(--white-color);
    color: var(--white-color);
    border-radius: 60px;
}

.button.transparentBlack {
    border: 1px solid var(--black-color);
    color: var(--black-color);
    border-radius: 60px;
}

.button.transparent:hover,
.button.transparent:focus-visible {
    background: var(--light-blue-color-with-transparency);
}

.button.smaller {
    height: 30px;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.04em;
}

.button.smallText {
    font-size: 14px;
    font-weight: 500;
}

.button.smallPaddings {
    padding: 10px 35px;
}

button.capitalize {
    text-transform: capitalize;
}

.button.circular {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
}
.button.smallBorder {
    font-size: 14px;
    padding: 10px 47px;
    font-weight: 500;
    border-radius: 60px;
    height: 38px;
    line-height: 12px;
    border: 1px solid var(--black-color);
    color: var(--black-color);
    border-radius: 60px;
}

.button.regularBorder {
    font-size: 14px;
    font-weight: 500;
    border-radius: 60px;
    height: 38px;
    line-height: 12px;
    border: 1px solid var(--black-color);
    color: var(--black-color);
    border-radius: 60px;
    width: 140px; /* Add this line to set a fixed width */
}

.button.hasReason {
    position: relative;
}

.button.hasReason::before {
    content: '';
    position: absolute;
    top: -5px; 
    left: -5px; 
    width: 12px; 
    height: 12px; 
    background-color: var(--brick-red-color);
    border-radius: 50%;
    border: 2px solid white; 
}
.button.hasReason {
    position: relative;
}

.button.hasReasonGreen::before {
    content: '';
    position: absolute;
    top: -5px; 
    left: -5px; 
    width: 12px; 
    height: 12px; 
    background-color: var(--light-green-color);
    border-radius: 50%;
    border: 2px solid white; 
}

.button.hasReasonGreen:disabled {
    color: black;
}

@media (max-width: 1440px) and (min-width: 780px) {

    .button.smallRounded {
        padding: 10px 25px;
    }

    .button.smallBorder {
        padding: 10px 25px;
    }

    .button.hasReasonGreen {
        padding: 10px 15px;
    }

    .button.regularBorder{
        width: 95px;
    }
}