.overlay {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    z-index: var(--z-index-modal-overlay);
}

.smallOverlay {
    z-index: var(--z-index-small-modal-overlay);
}

.largeOverlay {
    z-index: var(--z-index-modal-overlay);
}

.container {
    position: fixed;
    background-color: var(--main-background);
    max-height: var(--modal-height);
    bottom: 0;
    left: calc(50vw - var(--modal-width) / 2);
    width: var(--modal-width);
    z-index: var(--z-index-modal);
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    height: 0;
    animation: show 0.5s forwards;
}

.smallContainer {
    position: fixed;
    background-color: var(--main-background);
    height: var(--small-modal-height);
    max-height: var(--small-modal-height);
    left: calc(50vw - var(--small-modal-width) / 2);
    width: var(--small-modal-width);
    z-index: var(--z-index-small-modal);
    top: calc(50vh - var(--small-modal-height) / 2);
    display: flex;
    flex-direction: column;
    overflow: auto;
    animation: showSmall 0.3s forwards;
}

.introModalContainer {
    position: fixed;
    background-color: var(--main-background);
    height: var(--intro-modal-height);
    max-height: var(--intro-modal-height);
    left: calc(50vw - var(--intro-modal-width) / 2);
    width: var(--intro-modal-width);
    z-index: var(--z-index-modal);
    top: calc(50vh - var(--intro-modal-height) / 2);
    display: flex;
    flex-direction: column;
    overflow: auto;
    animation: showSmall 0.3s forwards;
}

.calendarContainer {
    position: fixed;
    background-color: var(--main-background);
    height: var(--calendar-modal-height);
    width: var(--calendar-modal-width);
    z-index: var(--z-index-small-modal);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    overflow: auto;
    animation: showCalendar 0.5s forwards;
}

.calendarContainer.withCheckBox {
    height: var(--calendar-modal-height-with-checkbox);
}

.container.large {
    width: var(--large-modal-width);
    left: calc(50vw - var(--large-modal-width) / 2);
}

.smallModalCloseButtonContainer {
    position: absolute;
    z-index: var(--z-index-small-modal);
    top: 25px;
    right: 25px;
}

.smallModalButton {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.modalClosed {
    height: 0;
    pointer-events: none;
    animation: hide 0.5s forwards;
}

.modalClosed.smallContainer {
    height: inherit;
    animation: hideSmall 0.3s forwards;
}

.modalClosedCalendar {
    animation: hideCalendar 0.3s forwards;
}

.header {
    display: flex;
    padding: 30px 30px 0 30px;
}

.header > * {
    z-index: var(--z-index-modal);
}

.content {
    padding: 0 170px;
    display: flex;
    flex-grow: 1;
}

.title {
    font-size: 31px;
    font-weight: 400;
    justify-self: center;
}

.blackCloseButton {
    margin-left: calc((50vw - var(--modal-width) / 2) + var(--modal-width));
    margin-top: calc(100vh - (var(--modal-height)));
    animation: showButton 0.5s forwards;
    overflow: auto;
}

.large .blackCloseButton {
    margin-left: calc(
        (50vw - var(--large-modal-width) / 2) + var(--large-modal-width)
    );
}

.calendarBlackCloseButton {
    margin-left: calc(
        (50vw - var(--calendar-modal-width) / 2) + var(--calendar-modal-width)
    );
    margin-top: calc((100vh - (var(--calendar-modal-height))) / 2);
}

.calendarBlackCloseButton.withCheckBox {
    margin-top: calc(
        (100vh - (var(--calendar-modal-height-with-checkbox))) / 2
    );
}

.blackCloseButtonOnClose {
    animation: hideButton 0.5s forwards;
}

.headerContainer {
    display: flex;
}

.headerText {
    margin: 0 0 60px 0;
    font-size: 31px;
    color: var(--black-color);
}

.center {
    display: flex;
    justify-content: center;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 170px 60px 170px;
    overflow-y: auto;
}

.large .modalContent {
    padding: 0;
}

.text {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: var(--black-color);
}

.upperCase {
    text-transform: uppercase;
}

.commentInput {
    background-color: var(--light-grey-color);
    color: var(--black-color);
    font-family: inherit;
    font-size: 18px;
    padding: 20px 30px;
    width: 100%;
    height: 240px;
    border: none;
    outline: none;
    resize: none;
}

.commentInput.small {
    height: 160px;
    min-height: 160px;
}

.commentInputLabel {
    display: block;
    width: 100%;
    color: var(--dark-grey-color);
    line-height: 32pt;
    font-size: 15px;
}

.actionButton {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.squareActionButton {
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    bottom: 0;
    left: 0;
    white-space: nowrap;
}

.squareActionButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 100%;
    height: 32px;
    pointer-events: none;
}

.calendarContainer .squareActionButton::before {
    display: none;
}

.squareActionButton * {
    width: 100%;
}

.squareActionButtonChild {
    flex: 1;
}

.actionButton.noPadding {
    padding-top: 0;
}

.spacer {
    height: 90px;
}

@keyframes show {
    from {
        height: 0;
        visibility: hidden;
    }
    to {
        height: var(--modal-height);
        visibility: initial;
    }
}

@keyframes showSmall {
    from {
        transform: scale(0.97);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes showCalendar {
    from {
        top: 100%;
        visibility: hidden;
    }
    to {
        top: 50%;
        visibility: initial;
    }
}

@keyframes showFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes hide {
    from {
        height: var(--modal-height);
        visibility: initial;
    }
    to {
        height: 0;
        visibility: hidden;
    }
}

@keyframes hideSmall {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.97);
        opacity: 0;
    }
}

@keyframes hideCalendar {
    from {
        opacity: 1;
        top: 50%;
    }
    to {
        opacity: 0;
        top: 100%;
    }
}

@keyframes showButton {
    from {
        margin-top: 100vh;
        visibility: hidden;
    }
    to {
        visibility: initial;
    }
}

@keyframes hideButton {
    from {
        visibility: hidden;
    }
    to {
        visibility: initial;
        margin-top: 100vh;
    }
}
