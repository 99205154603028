.container p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
}

.productListInReceivalFlowContainer {
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    scroll-behavior: smooth;
}

.productListInReceivalFlowContainer.sticky {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: var(--z-index-categories-bar-container);
    overflow: hidden;
    border-bottom: 1px solid #e3e3e3;
}

.spacer {
    height: 300px;
}

.tableHead {
    display: flex;
    padding: 25px 40px;
    font-size: 14px;
    color: var(--dark-grey-color);
    transition: var(--transition-slow);
    background-color: var(--white-color);
    z-index: 1;
}

.tableHead.compact {
    padding: 25px 0 20px;
    transition: 0s;
}

.tableHead.sticky {
    padding: 10px 40px;
    background: var(--main-background);
    position: fixed;
    top: calc(var(--page-topbar-height) + 75px);
    left: var(--sideNavigation-width);
    width: calc(100% - var(--sideNavigation-width));
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
}

.tableHead.sticky,
.tableHead.withBorder {
    border-bottom: var(--grey-color) 1px solid;
}

.tableHead.stickySummary {
    top: calc(var(--page-topbar-height) + 85px);
}

.tableHead.noHeader {
    top: var(--page-topbar-height);
}

.productListInReceivalFlowContainer .tableHead {
    padding: 25px 30px;
}

.productCategoryRow {
    display: flex;
    padding: 25px 40px;
    font-size: 18px;
    font-weight: 400;
    border-top: 1px solid var(--grey-color);
    cursor: pointer;
}

.productCategoryRow:hover {
    background-color: var(--light-grey-color);
    transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
}

.productCategoryRow.selected {
    background-color: var(--light-grey-color);
}


.productListInReceivalFlowContainer .productCategoryRow {
    padding: 25px 30px;
    border-bottom: none;
}

.productListInReceivalFlowContainer .categoryName {
    font-weight: 500;
    font-size: 24px;
    text-transform: capitalize;
    cursor: pointer;
}

.expandedProductsList {
    width: auto;
    background-color: var(--light-grey-color);
}

.confirmed {
    background-color: var(--light-grey-color);
}

.expandedProductsList.compact {
    margin-bottom: 15px;
}

.singleProductRow {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 20px 60px;
    width: 100%;
    border-bottom: 1px solid var(--grey-color);
    background-color: #ffffff;
}

.singleProductRow:hover { 
    background-color: var(--light-grey-color);
    transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
}

.singleProductRow.highlighted {
    background-color: var(--very-light-blue-color);
}

.singleProductRow.unavailable {
    background-color: var(--light-red-color);
}

.singleProductRow:not(.compact):first-child {
    border-top: 1px solid var(--grey-color);
}

.singleProductRow.compact {
    padding: 15px 40px 10px;
    margin-left: -40px;
    width: calc(100% + 80px);
}

.singleProductRow.muted {
    /* opacity: 0.2; */
    background-color: var(--light-grey-color);
}

.singleProductRow.muted p {
    opacity: 0.2;
}

.singleProductRow:last-child {
    border-bottom: 0;
}

.productListInReceivalFlowContainer .singleProductRow {
    padding: 25px 30px;
}

.productListInReceivalFlowContainer .singleProductRow:first-child {
    border-top: none;
}

.tableCell {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.productListInReceivalFlowContainer .tableCell p {
    margin: 0;
}

.tableCell.asButton {
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-family: inherit;
    cursor: pointer;
}

.withIcon .tableCell:nth-child(1) {
    width: 7.5%;
}

.tableCell:nth-child(1),
.compact .tableCell:nth-child(1) {
    width: 0;
}

.productListInReceivalFlowContainer .tableCell:nth-child(1) {
    width: 40%;
}

.withIcon .tableCell:nth-child(2) {
    width: 36%;
}

.tableCell:nth-child(2),
.compact .tableCell:nth-child(2) {
    width: 47.5%;
}

.productListInReceivalFlowContainer .tableCell:nth-child(2) {
    width: 15%;
}

.tableCell:nth-child(3) {
    width: 12%;
}

.compact .tableCell:nth-child(3) {
    width: 10%;
}

.productListInReceivalFlowContainer .tableCell:nth-child(3) {
    width: 20%;
}

.tableCell:nth-child(4) {
    width: 10%;
}

.compact .tableCell:nth-child(4) {
    width: 10%;
}

.productListInReceivalFlowContainer .tableCell:nth-child(4) {
    width: 25%;
    justify-content: end;
}

.tableCell:nth-child(5) {
    width: 17%;
}

.tableCell:nth-child(6) {
    width: 10%;
}

.tableCell:nth-child(7) {
    width: 3%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.singleProductCell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.singleProductCell.directionRow {
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.productListInReceivalFlowContainer .singleProductCell > p.primary {
    font-weight: 600;
    font-size: 15px;
}

.singleProductCell > p.primary {
    font-weight: 400;
    font-size: 15px;
}

.compact .singleProductCell > p.primary {
    font-size: 14px;
}

.singleProductCell > p.secondary {
    color: var(--grey-color-50);
    font-size: 14px;
    font-weight: 400;
}

.singleProductCell > p.secondaryBig {
    color: var(--dark-grey-color);
    font-size: 16px;
    font-weight: 400;
}

.tableCell img {
    transition: var(--transition-fast);
}

.tableCell .rotated {
    transform: rotate(-90deg);
}

.amountInput {
    border: none;
    background: var(--light-grey-color);
    height: 44px;
    width: 100px;
    text-align: center;
    font-size: 16px;
    outline: none;
    font-family: inherit;
    transition: 0.3s;
    padding: 0 12px;
}

.amountInput.error {
    color: var(--error-color);
}

.amountInput.lightInput {
    background-color: var(--main-background);
}

.amountInput.outlined {
    background-color: transparent;
    border: 1px solid var(--light-grey-color);
    border-radius: 4px;
}

.awaitingAction {
    border: 1px solid black;
    background: white;
}

.awaitingAction:disabled {
    border: none;
    background: var(--light-grey-color);
}

.singleProductRow.highlighted .amountInput {
    border: 1px solid var(--ash-grey-color);
}

.compact .amountInput {
    font-size: 16px;
}

.categoryTitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--black-color);
    margin: -10px 0 0 -40px;
    background: var(--light-grey-color);
    min-height: 40px;
    width: calc(100% + 80px);
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.changedProductsInfo {
    color: var(--grey-color-50);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.changedProductsInfoDot {
    height: 6px;
    width: 6px;
    background-color: rgb(39, 110, 241, 0.3);
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px 1px 0;
}

.closeIconEndingRow {
    align-items: center;
    cursor: pointer;
}
.commentButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 8px;
    margin-left: auto; 
}

@media (max-width: 1440px) {

    .tableHead.sticky {
        left: 0px;
        width: 100%;
    }

}