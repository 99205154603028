.container {
    display: flex;
    align-items: center;
}

.modalType {
    color: var(--dark-grey-color);
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 5px;
}

.title {
    font-size: 31px;
    margin-top: 0;
    margin-bottom: 40px;
}

.description {
    margin-bottom: 80px;
    color: var(--dark-grey-color);
    font-size: 16px;
}

.contactContainer {
    width: 310px;
}

.contactRow {
    display: flex;
    justify-content: space-between;
    color: var(--black-color);
}

.contactKey {
    font-weight: 400;
    font-size: 14px;
}

.contactValue {
    font-weight: 700;
    font-size: 14px;
}

.clearCacheDescription {
    margin-top: 40px;
    color: var(--dark-grey-color);
    font-size: 16px;
}

.clearCacheAction {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
