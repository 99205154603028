.badgeWrapper {
    margin-bottom: 30px;
    text-align: center;
}

.introduction {
    color: var(--dark-grey-color);
    text-align: center;
}

.loading {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    position: relative;
    padding: 3px;
    box-sizing: border-box;
    flex-shrink: 0;
    transition: opacity 0.3s ease;
    margin: auto;
    margin-top: 30px;
}

.loading::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid transparent;
    border-top: 2px solid var(--blue-color);
    border-right: 2px solid var(--blue-color);
    border-left: 2px solid var(--blue-color);
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    animation: spin 2s linear infinite;
    transform-origin: top left;
}

@keyframes spin {
    0% {
        transform: rotate(0deg) translate(-50%, -50%);
    }
    100% {
        transform: rotate(360deg) translate(-50%, -50%);
    }
}
