.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 0;
    cursor: pointer;
}

.modalTitle {
    font-size: 31px;
    color: var(--black-color);
    margin: 0 0 20px 0;
}

.changesList ul {
    padding: 0;
}

.changesList ul li {
    list-style-type: none;
    padding: 20px 0;
    border-bottom: 1px solid var(--grey-color);
}

.changesList ul li button {
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr minmax(max-content, 100px) 28px;
    background: none;
    border: 0;
    width: 100%;
    text-align: left;
}

.changesList ul li button.clickable {
    cursor: pointer;
}

.changeItem {
    display: flex;
    flex-flow: column;
}

.arrowWrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.itemNumber {
    color: var(--dark-grey-color);
}

.propertyRow {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-flow: row wrap;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--grey-color);
}

.propertyRow:not(:first-of-type) {
    margin-top: 18px;
}

.propertyRow:last-of-type {
    border: 0;
}

.label {
    flex: 100%;
    font-size: 18px;
    color: var(--black-color);
    margin: 0;
    text-transform: capitalize;
}

.value {
    margin: 0;
    font-size: 14px;
    color: var(--dark-grey-color);
}

.value.changed {
    text-decoration: line-through;
}

.messages h3 {
    margin: 40px 0 16px 0;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: normal;
}

.seeAllBtn {
    color: var(--blue-color);
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.noChangesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.noChangesText {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--grey-color-50);
}


