.container {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(42px);
}

.commentBubbleContainer {
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}
