.goBackButton {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 0;
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.goBackIcon {
    margin-right: 9px;
}
