.container {
    position: fixed;
    background-color: var(--main-background);
    height: 100%;
    width: 50vw;
    max-width: 50vw;
    top: 0;
    right: 0;
    transition: width 0.5s;
    z-index: var(--z-index-modal);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.modalClosed {
    width: 0;
    right: -100px;
}

.modalContent {
    min-width: 50vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.paddings {
    padding: 30px 42px;
}

.blackCloseButton {
    position: fixed;
    right: 50vw;
    transition: right 0.5s;
}

.blackCloseButtonClosed {
    right: 0;
    visibility: hidden;
}
