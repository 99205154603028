.searchInputContainer {
    display: flex;
    background: var(--grey-color);
    width: 0px;
    transition: width 0.2s;
    border: none;
    overflow: hidden;
    /* Move beneath search button */
    margin-left: -40px;
}

.searchInputContainer.active {
    width: 450px;
}

.searchInput {
    background: transparent;
    border: none;
    outline: none;
    padding: 0 45px;
    flex: 1;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.searchInput:focus-visible {
    border-bottom-color: var(--black-color);
}

.clearInputIconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    margin-right: 10px;
    cursor: pointer;
    /* Move on top of search input */
    margin-left: -40px;
}
