.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;
    width: 100%;
    padding: 14px 20px;
    border-bottom: 1px solid #e9ebed; 
    background-color: blue;
}

.container p {
    margin: 0;
    font-size: 16px;
}

.container .helper {
    color: #7a808d;
    margin: 0;
    font-size: 12px;
}

.containerRow {
    display: flex;
    align-items: flex-start;
}

.value {
    text-align: right;
    min-width: 100px;
}

.textContainer {
    display: flex; 
    width: 540px;
    /* background-color: aqua; */
}

.textContainerPeriod {
    display: flex;
    flex-direction: column;
    width: 270px;  
    padding-right: 8px;
    overflow-wrap:break-word;
    word-break: break-word;
}

.textContainerDate {
    display: flex;
    flex-direction: column;
    width: 150px; 
}

.date {
    opacity: 60%;
    font-size: x-small; 
}

.label { 
    display: flex;
} 