.button:hover {
    opacity: 0.9;
}

.button {
    border: none;
    cursor: pointer;
    background: var(--light-grey-color);
    min-width: 28px;
    min-height: 44px;
    border-radius: 100%;
    margin-left: 20px;
    background: none;
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
}

.button:disabled {
    opacity: 0.2;
    pointer-events: none;
}

.emptyCheckmark {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #F4F5F6;
}

.label {
    font-size: 14px;
    margin-left: 16px;
    color: var(--grey-sending-status-badge);
}