.container {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--page-topbar-height);
    top: 0;
    left: var(--sideNavigation-width);
    right: 0;
    padding: 0 21px 0 0px;
    margin-bottom: 5px;
    background: var(--main-background);
    border-bottom: 1px solid var(--grey-color);  
    z-index: 14;
}

.container.sticky {
    border-bottom: 1px solid var(--grey-color);
}

.searchAreaContainer {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
}

.spacer {
    height: var(--page-topbar-height);
    border-bottom: 1px solid var(--grey-color);  
    background: var(--main-background);
}

.syncIconsContainer {
    display: flex;
    align-items: center;
    height: 100%;
}

.bellButtonContainer {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.horizontalSpacer {
    display: block;
    margin: 0 20px 0 22px;
    height: 100%;
    width: 1px;
    background: #e9ebed;
}

.assortmentSearchToggleBtn {
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    margin: 5px 0 0 35px;
    width: 255px;
    height: 30px;
    background: var(--grey-color);
    border-radius: 60px;
    padding: 10px 30px;
}

.searchCopyText {
    padding-right: 5px;
}

.tooltipContainer {
    margin-left: 50px;
}

.assortmentToggleContainer {
    width: 450px;
}


/* Mobile styles */
@media (max-width: 1440px) {


    .container {

        left: 0;

    }

}