.pageContainer {
    padding: 0 50px 0 50px;
    display: flex;
    flex-direction: column;
    background-color: var(--light-grey-color);
    height: calc(100vh - 60px);
    justify-content: center;
    align-items: center;
    border-left: 1px solid var(--grey-color);
}

.header {
    margin-top: 30px;
    font-weight: bold;
    font-size: 31px;
    line-height: 42px;
    color: var(--black-color);
}

.text {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: var(--black-color);
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-color);
    border-radius: 60px;
    width: 203px;
    cursor: pointer;
}

.buttonText {
    color: var(--white-color);
    padding: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}