.contentContainer {
    padding: 48px 102px;
}

.contentContainer.lessSpacing {
    padding-top: 0;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.measuresText {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--grey-color-50);
    margin-left: 10px;
}

.reasonDropdownButton {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
}

.question {
    margin: 32px 0;
    border-bottom: 1px solid var(--grey-color);
}

.redValue {
    font-weight: 500;
    font-size: 14px;
    color: #ff5a5a;
}

.unitOfMeasure {
    padding-left: 3px;
}
