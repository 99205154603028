.bubbleWrapper {
    margin: 30px 0;
}

.senderInfo {
    display: flex;
}

.senderInfo.end {
    flex-flow: row-reverse;
}

.senderAvatarWrapper {
    width: 50px;
    height: 50px;
    background: var(--light-grey-color);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.senderDetails {
    margin-left: 18px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.senderInfo.end .senderDetails {
    margin-left: 0;
    margin-right: 18px;
}

.senderName {
    font-size: 14px;
    color: var(--black-color);
}

.sentAt {
    font-size: 14px;
    color: var(--text-disabled-color);
}

.messageContent {
    padding: 20px;
    background: var(--light-grey-color);
    margin: 20px 0 0;
}

.messageContent > * {
    margin: 0 0 6px;
    font-size: 16px;
    line-height: 26px;
}

.messageContent > *:last-child {
    margin-bottom: 0;
}

.messageContent p {
    overflow-wrap: break-word;
}

.genericUserProfileAvatar {
    stroke: var(--black-color);
}