.container {
    font-weight: 500;
}

.title {
    margin: 0 0 20px 0;
}

.cashDiscountRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0 18px 20px;
}

.summaryTitle {
    font-size: 31px;
    color: var(--black-color);
    margin: 0 0 20px 0;
}

.label {
    font-size: 18px;
    color: var(--black-color);
    margin: 0;
}

.subLabel {
    font-size: 14px;
    color: var(--dark-grey-color);
    margin: 0;
}

.keyValueGroupSpacing {
    margin-top: 20px;
}

.keyValueRow {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    margin-bottom: 10px;
}

.progress {
    height: 0;
    width: 100%;
    border-bottom: 4px solid var(--light-grey-color);
}

.progress__bar {
    border-bottom: 4px solid #b2d7dd;
    max-width: 100%;
}

.progress__bar__exceeded {
    border-color: var(--brick-red-color);
}

.chart {
    display: flex;
    justify-content: center;
}

.orderBudget {
    padding-bottom: 18px;
    margin: 0;
}

.orderTotal {
    margin: 0;
    padding-top: 18px;
}

.plateIcon {
    padding-top: 16px;
    align-self: center;
}
