.modalHeader {
    font-weight: 500;
    padding: 37px 40px 22px 40px;
    margin: 0;
}

.searchBoxContainer {
    padding: 16px 34px;
    border-bottom: 1px solid var(--grey-color);
}
.assortmentSearchToggleBtn {
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    margin: 5px 0 0 35px;
    width: 255px;
    height: 30px;
    background: var(--grey-color);
    border-radius: 60px;
    padding: 10px 30px;
}