.chip {
    text-transform: uppercase;
    text-align: center;
    padding: 7px 20px;
    font-size: 12px;
    border-radius: 100px;
    margin: 0 20px;
    display: inline-block;
    font-weight: normal;
}

.chip.withPadding {
    padding: 7px 40px;
}

.chip.orange {
    color: var(--orange-color);
    background: var(--light-orange);
}

.chip.gray {
    color: var(--dark-grey-color);
    background: var(--light-grey-color);
}

.textStatus.orange {
    color: var(--orange-color);
}




