:root {
    --main-background: #ffffff;

    --black-color: #020c16;
    --deep-black-color: #000000;
    --navy-blue: #00193e;
    --dark-grey-color: #555e68;
    --text-disabled-color: #acb4bb;
    --silver-color: #bfbfbf;
    --ash-grey-color: #dbdde0;
    --light-grey-color: #f4f5f6;
    --grey-color: #e9ebed;
    --white-color: #fff;
    --white-color-muted: #ffffff4d;
    --grey-very-light: #f9fafa;
    --grey-color-50: #7a828d;
    --grey-sending-status-badge: #7a808d;
    --grey-sending-offline-status-badge: #e3e4e5;
    --secondary-button-color: #dbdde0;
    --disabled-button-color: var(--light-grey-color);
    --disabled-button-text-color: #acb4bb;
    --border-color: #e9f0fe;

    --very-light-green-color: #f0faf3;
    --green-color: #3DA67E;
    --light-green-color-2: #3da77e;
    --light-green-color: #21a453;
    --green-aqua-color: #ecf6f2;
    --light-red-color: #ffefef;
    --red-color: #ff5a5a;
    --brick-red-color: #cd394d;
    --error-color: #e70a0a;
    --orange-color: #ff902a;
    --blue-color: #276ef1;
    --blue-text-color: #3b6ce9;
    --dark-blue-color: #00369f;
    --blue-highlight-color: #194aa9;
    --light-blue-color: #d4dfff;
    --light-blue-alternative-color: #b2d7dd;
    --very-light-blue-color: #eef3fe;
    --light-blue-color-with-transparency: #276ef11a;
    --bright-blue-color: #ecf2fe;
    --light-purple: #E0E4FF;
    --dark-purple: #3F1DA0;
    --light-orange: #FFEFE0;

    --transition-fast: 0.1s;
    --transition-regular: 0.2s;
    --transition-slow: 0.3s;

    --sideNavigation-width: 15.625rem;
    --modal-width: 70vw;
    --large-modal-width: 82vw;
    --small-modal-width: 40vw;
    --intro-modal-width: 35vw;
    --calendar-modal-width: 370px;
    --page-topbar-height: 60px;
    --order-topbar-height: 100px;
    --modal-height: calc(100vh - 48px);
    --small-modal-height: 40vh;
    --intro-modal-height: 60vh;
    --calendar-modal-height: 550px;
    --calendar-modal-height-with-checkbox: 600px;
    --footer-height: 170px;
    --z-index-default: 1;
    --manning-day-log-header-z-index: 2;
    --z-index-topbar: 13;
    --z-index-datepicker: 12;
    --z-index-button: 10;
    --z-index-categories-bar-container: 20;
    --z-index-dropdown: 21;
    --z-index-modal-overlay: 21;
    --z-index-modal: 22;
    --z-index-small-modal: 24;
    --z-index-small-modal-overlay: 23;
    --z-index-buttons-in-modal: 25;
    --z-index-searchbox-in-modal: 30;
    --z-index-toast: 35;
    --z-index-waves: 100;

    --current-modal-progressbar-width: 0;
    --previous-modal-progressbar-width: 0;

    /* react-tooltip */
    --rt-opacity: 1 !important;
}
