.buttonContainer {
    background-color: var(--light-grey-color);
    height: 60px;
    width: 100%;
    padding: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black-color);
    cursor: pointer;
}

.textContainer {
    padding: 0;
    margin: 0;
    display: block;
}


.textContainer p {
    margin: 0 0 8px 0;
    text-transform: uppercase;
    opacity: 0.5;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
}

.textContainer h3 {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.addButton {
    background-color: var(--black-color);
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}