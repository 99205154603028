.sectionHeading {
    font-size: 18px;
    color: var(--black-color);
    margin: 0;
    margin-top: 40px;
    margin-bottom: 15px;
    font-weight: 400;
}

.inputsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 20px;
    margin: 20px 0;
    /* Reset default browser styles */
    border: 0;
    padding: 0;
    margin: 0;
}

.inputsWrapper.narrow {
    grid-template-columns: repeat(auto-fit, minmax(140px, 170px));
}

.supplierNote {
    color: var(--text-disabled-color);
}

.detailsForm {
    padding-bottom: 60px;
}
