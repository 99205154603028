.container {
    position: fixed;
    top: calc(var(--page-topbar-height) + 12px);
    right: 13px;
    width: 234px;
    background: var(--white-color);
    border: 1px solid rgba(2, 12, 22, 0.12);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 3;
}

.container.notification {
    width: 250px;
}

.container.withPadding {
    padding: 20px 0;
}

.toggleButton {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.spacer {
    display: block;
    width: calc(100% - 32px);
    margin: 17px auto 23px auto;
    height: 1px;
    background: #020c16;
    opacity: 0.12;
}

.indicatorsContainer {
    padding-bottom: 23px;
}
