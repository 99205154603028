.headerContainer {
    margin-top: -80px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-grey-color);
}

.headerContainer h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    color: var(--black-color);
    margin-top: 0;
}

.headerContainerMarginTop {
    margin-top: 30px;
}

.measuresText {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--grey-color-50);
    margin-left: 10px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    padding: 0 100px 40px 100px;
}

.contentContainer.slimFit {
    padding: 0 0 40px;
    height: auto;
}

.contentContainer > * {
    width: 100%;
}

.itemName {
    margin-left: 100px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: var(--black-color);
}

.alignItemsStart {
    align-items: start;
}

.smallerMargins {
    margin: -10px 0;
}

.filesSectionContainer {
    max-width: var(--modal-width);
}
