.loader {
    width: 100%;
    height: 15px;
    background-color: var(--light-grey-color);
    position: relative;
    overflow: hidden;
    display: block;
}

.loader:after {
    content: '';
    background-color: var(--white-color);
    filter: blur(0.3rem);
    position: absolute;
    height: 100%;
    width: 20px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: linearProgress 1.4s linear infinite;
}

@keyframes linearProgress {
    0% {
        opacity: 1;
        left: 0%;
        right: 100%;
    }
    15% {
        right: 90%;
    }
    30% {
        right: 80%;
    }
    50% {
        left: 100%;
        right: 0%;
    }
    55% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
