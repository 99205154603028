.cell {
    padding: 10px 0;
    display: inline-block;
}

.cell svg {
    width: 18px;
    height: 12px;
}

.skipSelectionOption {
    padding: 24px 30px 24px 40px;
    border: 0;
    border-top: 1px solid var(--grey-color);
    width: 100%;
    background: var(--main-background);
    text-align: left;
    font-family: inherit;
    font-size: 15px;
    cursor: pointer;
    transition: color 0.2s ease, background 0.2s ease;
}

.skipSelectionOption:hover,
.skipSelectionOption:focus-visible,
.skipSelectionOption.active {
    background: var(--deep-black-color);
    color: var(--white-color);
}

.minHeightContentContainer {
    height: 100%;
}

.tooltipContainer {
    display: flex;
    margin-left: 20px;
}

.tooltipChildren {
    display: flex;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    height: 40px;
    font-size: 12px;
}

.tooltipInfoIcon{
    margin: 0 15px;
}

.tooltipLink {
    font-size: 15px;
    border: none;
    background: none;
    color: var(--blue-color);
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    bottom: 75px;
    left: 120px;
}