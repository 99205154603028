.centerText {
    text-align: center;
}
.centeredTextArea {
    height: calc(var(--modal-height) - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-weight: 600;
    color: var(--black-color);
    margin: 10px;
}

.subtitle {
    color: #7a808d;
}

.listContainer {
    height: calc(var(--modal-height) + 60px);
    width: 100vw;
}

.button {
    width: var(--modal-width);
    position: fixed;
    bottom: 0;
    left: calc(50% - var(--modal-width) / 2);
    z-index: var(--z-index-buttons-in-modal);
}

.bottomSpacer {
    height: 105px;
    color: transparent;
}

.smallBottomSpacer {
    height: 45px;
    color: transparent;
}
