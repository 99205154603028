.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 100px 40px 100px;
}

.orderWbPono {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: var(--grey-color-50);
}

.blueButton {
    text-transform: uppercase;
    color: var(--blue-color);
    cursor: pointer;
}

.detailsText {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--black-color);
}

.cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.fadedText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--black-color);
    opacity: 0.2;
}

.margin {
    margin-bottom: 30px;
}

.smallerMargin {
    margin-bottom: 5px;
}

.input {
    width: 45%;
    margin-top: 30px;
}

.noMargin {
    margin-top: -35px;
}

.text {
    font-weight: 400;
    font-size: 15px;
    color: var(--black-color);
}

.greyText {
    width: 40%;
    text-align: center;
    color: var(--grey-color-50);
}

.padding {
    padding: 10px 0 10px 40px;
}

.createItemButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid var(--grey-color);
    padding: 10px 0;
}

.createItemButton {
    margin: 10px 10px 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 9px;
    background-color: var(--light-grey-color);
}

.createItemTextSpacing {
    margin-bottom: 6px;
}

.bottomSpacer {
    height: 110px;
    color: transparent;
}

.customItemInput {
    margin: 15px 0 15px 0;
    width: 160px;
    height: 44px;
    background-color: var(--light-grey-color);
    outline: none;
    border: none;
    text-align: center;
    font-size: 16px;
    font-family: inherit;
}

.inputMargin {
    margin: 15px 0 15px 0;
}

.unitOfMeasureText {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: var(--grey-color-50);
    margin-left: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.notDisplay {
    display: none;
}

.confirmationModalTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: var(--black-color);
    margin: 40px 0 0 40px;
}

.confirmationModalText {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--grey-color-50);
    margin: 30px 30px 10px 40px;
}

.categoryNameCapitalized {
    text-transform: capitalize;
}

.threeColumns {
    width: 250px;
    display: grid;
    grid-template-columns: 100px 100px 50px;
    margin-right: -50px;
}

.threeColumns p {
    color: var(--grey-color-50);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.threeColumns p.changed {
    color: var(--orange-color);
}

.threeColumns p.textRight {
    text-align: right;
}

.threeColumns p.attachmentButton {
    margin: 0;
}

.columnHeadline {
    font-weight: 400;
    color: var(--grey-color-50);
}

.columnHeadline.textRight {
    text-align: right;
}

.comment {
    color: var(--grey-color-50);
}

.productTitle {
    max-width: 50%;
}

.bottomSpacer {
    margin-bottom: 50px;
}

.header {
    position: relative;
}

.header.withMarginBottom {
    margin-bottom: 85px;
}

.button {
    position: absolute;
    right: 30px;
    top: 65px;
}

.dateInput {
    margin-bottom: 10px;
    width: 30%;
}

.stepsProgressBar {
    margin-top: -42px;
}

.question {
    margin: 0 51px;
}

.redText {
    color: var(--red-color);
    font-size: 14px;
    font-weight: 500
}
