.addItemButton {
    display: block;
    color: var(--grey-color-50);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    height: 32px;
    background: var(--light-grey-color);
    border-radius: 110px;
    font-size: 14px;
    transition: 0.2s;
    font-weight: 500;
}

.addItemButton:hover {
    opacity: 0.9;
}

.addItemButtonText {
    margin-left: 8px;
}

.confirmAllButton {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
    padding: 0;
}

.confirmAllButton:hover {
    opacity: 0.9;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.attachmentButton {
    border: none;
    cursor: pointer;
    background: var(--light-grey-color);
    min-width: 28px;
    min-height: 44px;
    border-radius: 100%;
    margin-left: 20px;
    background: none;
    position: relative;
    padding: 0;
}

.attachmentButton:disabled {
    opacity: 0.2;
    pointer-events: none;
}

.attachmentButton.active::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--brick-red-color);
    transform: translate(-11px, 7px);
}
