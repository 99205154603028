.container {
    display: flex;
    flex-grow: 1;
    font-weight: 400;
    position: relative;
}

.pageContent {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.pageContentSlided {
    animation: slideIn 0.5s 0s 1 cubic-bezier(0.54, 0, 0, 1);
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 1440px) {

    .pageContent {
        margin-left: 0px;
    }

}