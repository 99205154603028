.remarksWrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.editor {
    padding: 20px calc(var(--sideNavigation-width) + 80px) 20px 0;
    border-top: 1px solid var(--grey-color);
    width: calc(100% + 80px);
    margin-left: -40px;
    background: var(--white-color);
    position: fixed;
    bottom: 0;
}

.editor form {
    max-width: 550px;
    margin: auto;
    position: relative;
}

.sendButtonWrapper {
    position: absolute;
    width: 200px;
    top: 5px;
    left: 570px;
}

.editButtonWrapper {
    width: 200px;
    top: 5px;
    display: flex;
    gap: 10px; /* Adjust the spacing as needed */
}

.editeditor {
    padding: 25px;
    border-top: 1px solid var(--grey-color);
    width: calc(100% - var(--sideNavigation-width));
    margin-left: -40px;
    background: var(--white-color);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
}

.editmessageInput {
    background-color: var(--light-grey-color);
    color: var(--black-color);
    font-family: inherit;
    padding: 10px 15px;
    font-size: 16px;
    margin-right: 15px;
    width: 500px;
    border: none;
    outline: none;
    resize: none;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.chatMessagesList {
    height: 100%;
    width: 100%;
    max-width: 550px;
    margin: auto;
    overflow: auto;
    display: flex;
    flex-flow: column;
}

.chatMessagesListWithCommentSection {
    margin: auto auto 80px auto;
}

.messageInput {
    background-color: var(--light-grey-color);
    color: var(--black-color);
    font-family: inherit;
    padding: 10px 15px;
    font-size: 16px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.messageActions {
    margin-bottom: 20px;
}

.messageActionsEditing {
    margin-bottom: 150px;
}

.messageInput:focus-visible {
    border-bottom-color: var(--black-color);
}

.editmessageInput:focus-visible {
    border-bottom-color: var(--black-color);
}

.noRemarksContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.remarksMessageExtraPadding {
    padding: 20px 0 30px 0;
}

.noRemarksText {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.noRemarksTextFaded {
    color: var(--grey-color-50);
}

.noRemarksTextBlue {
    color: var(--blue-color);
}

.scroll {
    overflow: visible;
}
