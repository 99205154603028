.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.contactText {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    right: 0;
    text-align: right;
}

.contactText.left {
    text-align: left;
    right: auto;
    font-weight: normal;
}

.contactText::after {
    content: '';
    border-color: transparent;
}

.blueText {
    color: var(--blue-color);
}
