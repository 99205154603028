.questionRowContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--light-grey-color);
    margin-top: 16px;
}

.questionRowContainer:nth-last-of-type(3) {
    border-bottom: none;
}

.questionText {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.radioButtonsContainer {
    display: flex;
    border: 1px solid var(--light-grey-color);
}

.radioButton {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--white-color);
    margin: 0;
}

.noAnswerSelected .radioLabel {
    color: var(--blue-color);
}

.radioLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 56px;
    color: var(--grey-sending-status-badge);
    cursor: pointer;
}

.radioLabel:nth-of-type(1) {
    border-right: 1px solid var(--light-grey-color);
}

.radioButton:checked + .radioLabel.yes {
    background: var(--green-aqua-color);
    color: var(--light-green-color-2);
}

.radioButton:checked + .radioLabel.no {
    background: var(--light-red-color);
    color: var(--red-color);
}

.remark {
    color: var(--grey-color-50);
    margin-top: 0;
    font-size: 14px;
}