.status {
    text-transform: uppercase;
    font-weight: bold;
}

.message {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--deep-black-color);
    text-align: center;
    padding: 20px 0 10px 0;
}

.largerPadding {
    padding: 20px 20px 40px 20px;
}

.price {
    font-weight: bold;
    font-size: 31px;
    line-height: 42px;
    color: var(--blue-color);
    text-align: center;
    padding: 0 0 10px 0;
}

.amountRemark {
    margin-top: 0;
    font-size: 14px;
}

.affectedQuantity,
.notUsable {
    color: var(--red-color);
    text-transform: lowercase;
}

.notUsable,
.usable {
    text-transform: capitalize;
}

.usable {
    color: var(--green-color);
}
