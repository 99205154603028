.container {
    padding: 40px 100px;
}

.container h3 {
    color: var(--black-color);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}

.container p {
    color: var(--grey-color-50);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
}