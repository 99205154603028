.loadingSpinnerContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

@keyframes show {
    to {
        opacity: 1;
    }
}

.loadingSpinner {
    display: block;
    position: relative;
    /* Hide screen-reader accessible text */
    text-indent: -9999px;
}

.loadingSpinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--loading-spinner-size);
    height: var(--loading-spinner-size);
    margin-top: calc(var(--loading-spinner-size) / 2 * -1);
    margin-left: calc(var(--loading-spinner-size) / 2 * -1);
    border-radius: 50%;
    border: calc(var(--loading-spinner-size) / 10) solid var(--ash-grey-color);
    border-top-color: var(--black-color);
    animation: spinner 0.6s linear infinite;
    will-change: transform;
    backface-visibility: hidden;
}

.loadingSpinner.delayed {
    opacity: 0;
    animation: show 0.5s ease 0.5s forwards;
    will-change: opacity;
}

.loadingSpinner.delayed:before {
    animation: spinner 0.6s linear 0.5s infinite;
}
