.backgroundContainer {
    padding-top: 1px;
    background-color: var(--light-grey-color);
    min-height: calc(100vh - var(--page-topbar-height) - 158px);
    height: 100%;
}

.greyGap {
    background-color: var(--light-grey-color);
    height: 20px;
    width: 100%;
}

.createNewOrderButtonsContainer {
    padding: 19px 38px;
    height: 98px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: var(--white-color);
}

.ordersListContainer {
    background-color: var(--white-color);
}

.ordersListContainer.withMargin {
    margin-top: 20px;
}

.orderListHeader {
    padding: 30px 36px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.orderListHeader > button {
    color: var(--blue-color);
}

.ordersListContainer h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.border {
    border-top: 1px solid var(--grey-color);
}

.emptyStateTitleContainer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
