.section {
    margin-bottom: 50px;
    font-size: 14px;
}

.settingRow {
    display: flex;
    justify-content: space-between;
    height: 50px;
    border-top: 1px solid var(--light-grey-color);
    font-size: 16px;
}

.title {
    font-weight: 400;
    font-size: 24px;
    margin: 30px 0 4px 0;
    padding: 0;
}

.subtitle {
    margin: 0;
    padding: 0;
    color: var(--dark-grey-color);
}

.rowName {
    color: var(--grey-color-50);
}

.textButton {
    color: var(--blue-color);
    cursor: pointer;
}

.disabled {
    color: var(--grey-color-50);
}

.sectionTitle {
    text-transform: uppercase;
}
