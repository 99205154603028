.react-datepicker {
    border: none !important;
    background-color: #fff !important;
    font-size: 1rem !important;
    padding: 10px;
    border-radius: 0 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.react-datepicker-inline {
    box-shadow: none;
    padding: 0;
}

.react-datepicker-popper {
    padding-top: 0 !important;
    padding-bottom: 10px !important;
    z-index: var(--z-index-datepicker) !important;
    position: relative !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    border: none !important;
    background-color: var(--white-color) !important;
}

.react-datepicker__day {
    border-radius: 8px !important;
    cursor: pointer !important;
    line-height: 1.7rem;
    margin: 9px;
    display: inline-flex !important;
    width: 40px !important;
    height: 40px !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 16px !important;
}

.react-datepicker__day:hover,
.react-datepicker__hovered-range {
    background-color: var(--grey-color) !important;
}

.react-datepicker__day-name {
    color: var(--grey-sending-status-badge) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    margin: 9px;
    display: inline-flex !important;
    width: 40px !important;
    height: 40px !important;
    justify-content: center !important;
    align-items: center !important;
}

.react-datepicker__day--disabled,
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default !important;
    color: #ccc !important;
    background-color: transparent !important;
}

.react-datepicker__day--today {
    color: var(--blue-color) !important;
    font-weight: 600 !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    color: inherit !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    background-color: var(--blue-color) !important;
    color: var(--white-color) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__header {
    font-weight: 500 !important;
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}







