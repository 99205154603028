@import url('./reactDatePicker.css');
@import url('./radixDropdown.css');
@import url('./customScrubber.css');

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

a {
    color: inherit;
    text-decoration: none;
}

button,
input {
    font-family: inherit;
}

* {
    box-sizing: border-box;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/
@font-face {
    font-family: 'Satoshi';
    src: url('../public/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('../public/fonts/Satoshi-Variable.woff') format('woff');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
}

.blackCloseButton {
    transition: height 0.5s;
    border: none;
    cursor: pointer;
    background: var(--black-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.iconButton {
    border: none;
    cursor: pointer;
    background: var(--light-grey-color);
    width: 44px;
    height: 44px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.iconButton__big {
    width: 60px;
    height: 60px;
}

.iconButton__medium {
    width: 32px;
    height: 32px;
}

.cursorPointer {
    cursor: pointer;
}

.pageHeading {
    margin: 0;
    font-size: 31px;
    font-weight: 700;
    transition: var(--transition-slow);
}

.hr {
    border: 0;
    border-top: 1px solid var(--grey-color);
    width: 100%;
    margin: 0;
}

.hr--marginTop {
    margin-top: 60px;
}

.hr--margin {
    margin-top: 20px;
}

.transitionRegular {
    transition: 0.2s;
}

.rotated90 {
    transform: rotate(90deg);
}

.rotated180 {
    transform: rotate(180deg);
}

.rotated270 {
    transform: rotate(270deg);
}

.rotated-90 {
    transform: rotate(-90deg);
}

.no-margin {
    margin: 0;
}

.no-margin--top {
    margin-top: 0;
}

.no-margin--bottom {
    margin-bottom: 0;
}