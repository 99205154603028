.fileInput {
    position: fixed;
    top: -100px;
    left: -100px;
}

.customInput {
    font-size: 16px;
    color: var(--blue-color);
    cursor: pointer;
}

.dropFileLabel {
    margin-bottom: 24px;
}

.dropFileLabel:focus-within {
    outline: 2px solid var(--blue-color);
}

.noImagesInfo {
    color: var(--grey-color-50);
}

.thumbnailContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.thumbnailContainer > img {
    object-fit: cover;
}

.thumbnailContainer > p {
    font-size: 16px;
}

.thumbnail {
    width: 30px;
    height: 35px;
    border-radius: 2px;
}

.imageSize {
    color: var(--grey-color-50);
    margin-right: 22px;
}

.imageSizeNoMargin {
    margin-right: 0;
}

.sizeAndRemoveButtonContainer {
    display: flex;
}

.removeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.fullScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
}

.imageFullScreenContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fileContainer {
    align-self: center;
    position: relative;
    justify-content: center;
    justify-self: center;
    width: var(--modal-width);
    align-items: center;
    text-align: center;
    display: flex;
    background-color: var(--ash-grey-color);
    z-index: 100;
    height: var(--modal-height);
    min-height: max-content;
}

.pdfController {
    position: fixed;
    top: 90%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0px;
    transform: translate(-50%, -50%);
    color: white;
    background-color: black;
    border-radius: 20px;
    gap: 25px;
    user-select: none;
    width: 210px;
}

.pdfArrowsClickArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    user-select: all;
    cursor: pointer;
    height: 24px;
}

.fullScreenCloseButton {
    background-color: var(--main-background);
    border: none;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pdfIconWrapper {
    padding: 5px 3px 2px 3px;
    background-color: var(--grey-color);
    border-radius: 2px;
}

.fileName {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}
