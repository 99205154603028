.modalContainer p {
    padding: 0;
    margin: 0;
}

.modalContainer {
    padding: 20px 104px;
    height: var(--modal-height);
    overflow-y: auto;
}

p.categoryName {
    font-size: 21px;
    padding: 20px 0;
}

.filterCategoryToggleButton {
    background: var(--light-grey-color);
    border: none;
    padding: 5px 19px;
    border-radius: 100px;
    margin: 9px 15px 9px 0;
    font-size: 16px;
    cursor: pointer;
    transition: var(--transition-fast);
}

.filterCategoryToggleButton.active {
    background: var(--black-color);
    color: var(--white-color);
}

.filterButtonsContainer {
    display: flex;
    flex-wrap: wrap;
}

.clearBtn {
    margin: 9px 0;
}
