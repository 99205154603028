.message {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--deep-black-color);
    text-align: center;
    padding: 20px 0 10px 0;
}

.largerPadding {
    padding: 20px 0 40px 0;
}

.price {
    font-weight: bold;
    font-size: 31px;
    line-height: 42px;
    color: var(--blue-color);
    text-align: center;
    padding: 0 0 10px 0;
}

.question {
    border-bottom: 1px solid var(--grey-color);
}

.questionRemark {
    margin: 0;
}