.container {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.modalType {
    color: var(--dark-grey-color);
    font-size: 14px;
    margin: 0 0 5px 0;
}

.title {
    font-size: 31px;
    margin: 0 0 40px 0;
}

.description {
    margin-bottom: 40px;
    color: var(--dark-grey-color);
    font-size: 16px;
    font-weight: 500;
}

.descriptionCentered {
    text-align: center;
}

.description.error {
    color: #ff5a5a;
    max-width: 600px;
}

.blueText {
    color: var(--blue-color);
    cursor: pointer;
}

.statusBadgeContainer {
    margin-bottom: 30px;
}

.additionalDescription {
    margin-bottom: 30px;
}
