.container {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}

.waveAnimation {
    z-index: var(--z-index-waves);
}

.slider {
    background-color: var(--blue-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation: slideIn 0.5s 0s 1 cubic-bezier(0.54, 0, 0, 1);
}

.finalSlider {
    background-color: var(--white-color);
    z-index: 10;
    position: fixed;
    top: 100vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation: slideIn 0.5s 0s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.logoContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logoAnimation {
    animation: logoAnimation 0.5s 0s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.logoLoadingAnimation {
    animation: logoLoadingAnimation 0.5s 2s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loadingTextContainer {
    opacity: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    animation: loadingTextAnimation 0.5s 2s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loadingText {
    font-weight: 300;
    font-size: 36px;
    color: var(--white-color);
    text-align: center;
}

.text {
    position: fixed;
    bottom: -50px;
    font-weight: 300;
    font-size: 18px;
    color: var(--white-color);
    animation: textSlideIn 0.5s 0.5s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.gatewayTextContainer {
    left: 72px;
}

.versionTextContainer {
    right: 72px;
}

.loaderContainer {
    opacity: 0;
    position: fixed;
    top: 55px;
    left: 72px;
    animation: fadeIn 0.5s 2s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loader {
    width: 8vw;
    height: 1px;
    margin: 0 auto;
    position: relative;
}

.loader:before {
    content: '';
    background-color: rgba(249, 250, 250, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.loader .loaderBar {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: var(--white-color);
    width: 0;
    animation: linearProgress 2s linear infinite;
}

.loadedDataContainer {
    opacity: 0;
    position: fixed;
    left: 72px;
    top: 75px;
    animation: fadeIn 0.5s 2s 1 cubic-bezier(0.54, 0, 0, 1) forwards;

}

.loadedDataContainer p {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--white-color);
    opacity: 0.5;
}

.loadedDataContainer p:first-child {
    opacity: 1;
    padding: 20px 0;
    animation: firsEl 1s 2.4s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loadedDataContainer p:nth-child(2) {
    animation: nEl 2s 3.2s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loadedDataContainer p:nth-child(3) {
    animation: nEl 2s 5s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loadedDataContainer p:nth-child(4) {
    animation: nEl 2s 6.8s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loadedDataContainer p:nth-child(5) {
    animation: nEl 2s 8.6s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

.loadedDataContainer p:last-child {
    opacity: 0;
    padding-top: 20px;
    animation: lastEl 2s 10.4s 1 cubic-bezier(0.54, 0, 0, 1) forwards;
}

@keyframes lastEl {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        transform: translateY(-20px);
        opacity: 1;
    }
}

@keyframes nEl {
    0% {
        opacity: 0.5;
    }
    25% {
        transform: translateY(-10px);
        opacity: 1;
    }
    75% {
        transform: translateY(-10px);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0.5;
    }
}

@keyframes firsEl {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}


@keyframes linearProgress {
    0% {
        left: 0;
        right: 100%;
        width: 0;
    }
    10% {
        left: 0;
        right: 70%;
        width: 30%;
    }
    90% {
        right: 0;
        left: 70%;
        width: 30%;
    }
    100% {
        left: 100%;
        right: 0;
        width: 0;
    }
}

@keyframes slideIn {
    0% {
        top: 100vh;
    }
    100% {
        top: 0;
    }
}

@keyframes logoAnimation {
    0% {
        width: 320px;
        height: 300px;
    }
    100% {
        width: 162px;
        height: 152px;
    }
}

@keyframes logoLoadingAnimation {
    0% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(-50%, -130px) scale(80%);
    }
}

@keyframes textSlideIn {
    0% {
        bottom: -50px;
        opacity: 1;
    }
    100% {
        bottom: 82px;
        opacity: 1;
    }
}

@keyframes hideLoader {
    0% {
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loadingTextAnimation {
    0% {
        opacity: 0.2;
        height: 500px;
    }
    100% {
        opacity: 1;
        height: 250px;
    }
}
