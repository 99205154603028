.searchResultsHeader {
    padding: 33px 40px 15px 40px;
}

.querySpan {
    color: var(--blue-color);
}

.resultRow {
    cursor: pointer;
}

.searchResultsTableHead > *:nth-child(1),
.resultRow > *:nth-child(1) {
    width: 50%;
}

.searchResultsTableHead > *:nth-child(2),
.resultRow > *:nth-child(2) {
    width: 40%;
}

.searchResultsTableHead > *:nth-child(3),
.resultRow > *:nth-child(3) {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


.assortmentSearchToggleBtn {
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    margin: 5px 0 0 35px;
    width: 255px;
    height: 30px;
    background: var(--grey-color);
    border-radius: 60px;
    padding: 10px 30px;
}

.assortmentToggleContainer {
    width: 450px;   
    position: fixed;
}

