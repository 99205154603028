.toggleButtonLabel {
    margin-right: 11.2px;
    font-weight: 600;
}

.toggleButtonContainer {
    flex-basis: 100%;
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
}

.toggleButtonContainerMargin {
    margin-right: 30px;
}
