.contentContainer {
    padding: 0 130px 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1024px;
}

.contentContainer p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: var(--dark-grey-color);
    text-align: center;
}

.contentContainer button:first-child {
    margin-right: 30px;
}

.container p,
.container h3 {
    margin: 0;
    padding: 0;
}

.container {
    text-align: center;
    margin-bottom: 80px;
}

.dialogPreTitle {
    color: var(--dark-grey-color);
    margin-bottom: 5px;
    font-size: 14px;
}

.dialogTitle {
    font-size: 31px;
}
