@import 'react-scrubber/lib/scrubber.css';

.scrubber.horizontal .bar,
.scrubber.horizontal.hover .bar {
    height: 72px;
    background-color: var(--ash-grey-color);
    background: repeating-linear-gradient(
            90deg,
            #fff calc(var(--scrub-value, 2px) - 2px),
            #fff var(--scrub-value, 2px),
            var(--ash-grey-color) var(--scrub-value, 2px),
            var(--ash-grey-color) calc(var(--scrub-value, 2px) + 8px)
    );
    border-radius: 12px;
}

.scrubber.horizontal .bar__progress {
    background: transparent;
    border-radius: 12px;
}

.scrubber.horizontal .bar__thumb {
    display: none;
}
