.container {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-grey-color);
    margin-top: 28px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    font-weight: 500;
    font-size: 21px;
    cursor: pointer;
    transition: 0.2s;
    border: 0;
}

.container:hover {
    background-color: var(--grey-color);
}

.container.selected {
    background-color: var(--black-color);
    color: var(--white-color);
}
