.stepsContainer {
    padding: 30px 0 0 0;
    text-align: center;
}

.stepsText {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark-grey-color);
}

.progressBar {
    height: 4px;
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.progressBar:before {
    content: '';
    position: absolute;
    background-color: var(--very-light-blue-color);;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.progressBar .bar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--blue-color);
    width: var(--current-modal-progressbar-width);
    transition: width 1s ease;
    animation: progressBarAnimation 1s;
}

@keyframes progressBarAnimation {
    from {
        width: var(--previous-modal-progressbar-width);
    }
    to {
        width: var(--current-modal-progressbar-width);
    }
}
