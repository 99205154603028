.productId {
    color: var(--dark-grey-color);
    font-size: 14px;
    margin: 0;
}

.headerText {
    margin: 40px 0 0 0;
    font-size: 31px;
    color: var(--black-color);
}

.categories {
    display: flex;
    padding: 20px 0 40px 0;
}

.categoryBadge {
    padding: 5px 20px;
    border-radius: 100px;
    margin: 0;
    background-color: var(--light-grey-color);
}

.label {
    font-size: 18px;
    color: var(--black-color);
    margin: 0;
    text-transform: capitalize;
}

.value {
    margin: 0;
}

.propertyRow {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--grey-color);
}

.quantityLabel {
    align-self: center;
}

.propertyRow:not(:first-of-type) {
    margin-top: 18px;
}

.propertyRow:last-of-type {
    border: 0;
}

.minusButton {
    margin-right: 10px;
}

.unitOfMeasure {
    display: inline-block;
    color: var(--grey-color-50);
    font-size: 15px;
    font-weight: 400;
}

.commentAuthor {
    display: flex;
    margin-bottom: 16px;
}

.spacer {
    height: 41px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 170px 60px 170px;
    overflow-y: auto;
}
