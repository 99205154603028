.figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin: 0;
    background: var(--light-grey-color);
    color: var(--black-color);
    max-height: 120px;
    width: 100%;
}

.figure.inverted {
    background: var(--black-color);
    color: var(--white-color);
}

.figure.white {
    background: var(--white-color);
    color: var(--black-color);
}

.figcaption {
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    padding-bottom: 4px;
}

.figcaption.faded {
    color: var(--grey-color-50);
    font-size: 14px;
    font-weight: 500;
}

.captionRow {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.rowWithTabs {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}

.tab {
    border: none;
    color: var(--grey-color-50);
    padding: 6px 0 6px 0;
    border-radius: 3px;
    font-size: 12px;
    line-height: normal;
    font-weight: 700;
    cursor: pointer;
    width: 30px;
    text-align: center;
}

.tab.active {
    color: var(--white-color);
    background-color: var(--black-color);
}

.tab.inactive {
    background-color: transparent;
    opacity: 0.5;
}

.tab.inactive:hover {
    background-color: var(--grey-color);
}