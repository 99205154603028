.dropBanner {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: var(--blue-color);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease forwards;
}

.dropBanner figure {
    text-align: center;
    font-size: 31px;
    color: var(--white-color);
    pointer-events: none;
}

.dropBanner figure figcaption {
    margin-top: 12px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.95;
    }
}

.dropExplainer {
    position: relative;
    background: var(--white-color);
    display: flex;
    animation: fadeIn 0.3s ease forwards;
    align-items: center;
    justify-content: center;
    min-height: 116px;
    width: 100%;
    outline: 2px dashed var(--grey-color-50);
}

.dropExplainer:hover {
    background-color: var(--very-light-blue-color);
    outline: 2px dashed var(--blue-color);
}

.dropExplainer strong {
    font-weight: 400;
    display: block;
    color: var(--black-color);
}

.dropExplainer p {
    color: var(--grey-color-50);
    margin-left: 16px;
}

.dropExplainer svg path {
    fill: var(--blue-color);
}
