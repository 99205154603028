.headerContainer {
    text-align: left;
    padding: 12px 15px 20px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.changeMonthBtnContainer {
    display: inline-flex;
    gap: 8px;
}

.changeMonthBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    background-color: var(--ash-grey-color);
    border: none;
    cursor: pointer;
    padding: 0;
}

.dateString {
    flex: 1;
    margin: 0;
    color: var(--black-color);
    font-weight: 600;
    font-size: 24px;
}

.dateStringYear {
    color: var(--grey-sending-status-badge);
}

.label {
    margin: 0;
    cursor: default;
}
