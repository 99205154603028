.starRatingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.smallStarRatingContainer {
    margin: 60px 0;
}

.title {
    text-transform: capitalize;
}

.fadeInAnimation {
    animation: fadeIn 550ms;
}

.fadeOutAnimation {
    animation: fadeOut 550ms;
}

@keyframes fadeOut {
    0% {
        opacity: 100%;
        transform: initial;
    }
    100% {
        opacity: 0%;
        transform: translateX(-100px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
        transform: translateX(100px);
    }
    100% {
        opacity: 100%;
        transform: initial;
    }
}