.content {
    font-size: 12px;
    margin: 8px 0 0 0;
    font-weight: 400;
    color: var(--grey-sending-status-badge);
}

.blue {
    color: var(--blue-color);
}
